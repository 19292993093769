.title{
    margin-top: 0.5em;
    font-size: 2em;
    border: 1px solid transparent;
}
.title:hover{
    border: solid 1px black;
}

.sidebar{
    background-color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    position: fixed;
    left: 0px;
    top: 25%;
}

/* .editingSpace{
    background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
    padding-top:20px;
    padding-bottom: 20px;
    min-height: 100%;
}
.mainEditor{
    padding: 30px;
} */

.fcFocus{
    position: absolute;
    top:0;
    left:0;
    height: 100%;
    width: 100%;
}
.frontNoValue::before{
    position:absolute;
    top:1em;
    left:1em;
    content:"Flashcard Front";
    color:lightgray;
}
.backNoValue::before{
    position:absolute;
    top:1em;
    left:1em;
    content:"Flashcard Back";
    color:lightgray;
}
.tagListNoValue::before{
    position:absolute;
    top:1em;
    left:1em;
    content:"Tags";
    color:lightgray;
}